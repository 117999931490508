@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';
@use 'stylesheets/border_classes.module.scss';
@use 'stylesheets/layout_classes.module.scss';

body {
    background-color: $veryLightGray;
}

.headerWrapper {
    display: flex;
    flex-direction: column;
    background-color: $purple;
}

.digitalCard {
    display: flex;
    flex-direction: row;
    background-color: $white;
    height: 3rem;
    padding: 0.6rem 0.7rem;
    column-gap: 0.5rem;
    align-items: center;
    border-bottom: 1px solid #ededed;

    .headerText {
        color: $black;
    }

    .menu {
        width: 11rem;
        margin-top: -1.5rem;
        margin-left: 1rem;
    }

    .digIconWrapper {
        margin-top: 4px;
        margin-right: 7px;
    }
}

.logoContainer {
    width: 100vw;
    padding: 16px 16px 8px 16px;
    position: sticky;
    background-color: $purple;
    top: 0;
    z-index: 1200;
}

.logoWrapper {
    display: flex;
    flex-direction: row;
    color: $white;
    align-items: center;
    justify-content: space-between;

    .iconWrapper {
        @extend .fullRounded;
        background: $darkPurple;
        padding: 6px;
        display: flex;
        column-gap: 0.5rem;
    }

    .iconSpan {
        vertical-align: text-top;
    }

    .ptsText {
        font-weight: $bold;
        font-size: 1.1rem;
        margin-top: -0.5rem;

        img {
            vertical-align: middle;
        }
    }
}

.message {
    display: flex;
    flex-direction: row;
    justify-content: end;
    padding-right: 1rem;
    color: #fff;
    background-color: #5b0069;
    padding-bottom: 10px;
}

.underlineText {
    text-decoration: underline;
}

.iconImg {
    vertical-align: middle;
    padding-right: 5px;
}

.wrapper {
    color: $black;
    position: relative;
    margin: 0 1rem 1.25rem 1rem;
    display: flex;
    background: $white;
    border-radius: 6.5rem;
    height: 2.625rem;

    .slidingPill {
        position: absolute;
        top: 0;
        height: 100%;
        z-index: 500;
        background-color: #cc0000;
        border-radius: 6.5rem;
    }

    // border: 2px solid $white;
    .leftActive,
    .rightActive {
        color: $white;
        font-weight: $bold;
        border-radius: 6.5rem !important;
    }

    .bodyText {
        line-height: 0.9rem;
        font-size: 1rem;
        font-weight: $bold;
    }

    .leftBtnWrapper,
    .rightBtnWrapper {
        cursor: pointer;
        display: inline-block;
        text-align: center;
        line-height: 0.8rem;
        vertical-align: top;
        flex-grow: 1;
        font-weight: $normal;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        transition-property: color;
        transition-duration: 1s;
    }
}

.coinIcon {
    width: 22px;
    height: 22px;
}

.showName {
    position: absolute;
    top: 25px;
    opacity: 1;
    right: 16px;
}

.hideName {
    position: absolute;
    top: 0px;
    opacity: 0;
    right: 16px;
}

.pointshow {
    position: absolute;
    top: 20px;
    opacity: 1;
    right: 16px;
    padding: 0.3rem 0.6rem 0.3rem 1rem;
}

.pointHide {
    position: absolute;
    top: 40px;
    opacity: 0;
    right: 16px;
    padding: 0.3rem 0.6rem 0.3rem 1rem;
}

.scanIcon {
    color: $purple;
    font-weight: 900;
}

.scanIconLast {
    margin-left: 0.5rem;
}

.lineSeparator {
    width: 100%;
    height: 1px;
    color: #ededed;
}

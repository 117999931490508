@use '../base.module.scss';
@use 'stylesheets/variables' as *;

.carouselContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    padding-bottom: 1rem;
}

.animationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: white;
}

.topContainer {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-bottom: 1rem;
}

.textAlign {
    line-height: 16px;
    text-align: center;
    color: #000000;
}

.infoList {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.infoListItem {
    display: flex;
    align-items: flex-end;
    column-gap: 1rem;
}

.infoListIcon {
    min-width: 90px;
}

.disclaimerContainer {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
}

.disclaimerTopContent {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    row-gap: 20px;
}

.bottomContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}

.topGraphic {
    display: flex;
    justify-content: flex-end;
    background-color: white;
}

@media screen and (min-width: 420px) {
    .topGraphic {
        margin: 0 -30px -3.5rem -30px;
    }
}

@media screen and not (min-width: 420px) {
    .topGraphic {
        margin: 0 -16px -3.5rem -16px;
    }
}

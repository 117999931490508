@use 'stylesheets/variables' as *;

.rewardingShipping {
    margin-bottom: 20px;
    margin-left: 16px;
    margin-right: 16px;
    
}
.noBottomMargin {
    margin-bottom: 0px;
    margin-left: 16px;
    margin-right: 16px;
}
.rewardingShippingFullWidth {
    margin: 0;
    border-radius: 0px !important;
    border-top: 1px solid rgba($black, 0.1);
    border-bottom:1px solid rgba($black, 0.1);
}
.tileAdorment {
    position:absolute;
    right: 0;
    top: 0;
    transform: scaleX(-1);
}
.cardHeader {
    display: flex;
    position: relative;
}


.description {
    margin: 12px 12px 5px 12px;
    flex-grow: 1;
    padding-left: 15px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: end;
}
.helperTextDsc {
    width: 60%;
}
.learnMore {
    color: $lightblue !important;
    margin: 1rem 0 0rem 0rem !important;
    font-size: 14px !important;
}

.pointsHighlighted {
    font-family: "StaplesNormsBold";
    line-height: 1.5;
    color: $purple;
    font-size: 28px;
    margin: 5px 0 -8px 0;
}


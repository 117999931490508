@font-face {
    font-family: 'StaplesNormsRegular';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/StaplesNormsPro-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'StaplesNormsMedium';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/StaplesNormsPro-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'StaplesNormsBold';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/StaplesNormsPro-Bold.otf') format('opentype');
}

/* * {
    font-family: 'StaplesNorms', 'Roboto', 'Helvetica Neue', sans-serif;
  } */

body {
    margin: 0;
    overscroll-behavior-y: contain;
    height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

/* Removing the autofill gray box; 'transparent' doesn't work for box-shadow */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1rem white inset !important;
    box-shadow: 0 0 0 1rem white inset !important;
    border-radius: 1.5rem;
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
    #walletTab .react-swipeable-view-container {
        width: 95%;
    }

    #localImpactButton {
        width: 100%;
    }
}

@media only screen and (min-width: 360px) and (max-width: 740px) {
    #walletTab .react-swipeable-view-container {
        width: 98%;
    }

    #localImpactButton {
        width: 100%;
    }

    .horizontalCardActionSection {
        column-gap: 1rem !important;
    }

    .activateButton {
        padding: 8px 15px !important;
    }

    .showExp {
        gap: 0px !important;
    }
}

@media screen and (min-width: 741px) {
    #walletTab .react-swipeable-view-container {
        width: 90%;
    }
}

/* Adding this causes overflowing on select your school page (rewards-enroll-confirmSchool.tsx) */
/* .react-swipeable-view-container div {
  overflow: visible;
} */

#rewards-available-to-spend-card {
    margin-top: 0 !important;
    margin-bottom: 2rem;
}

#give-to-favorites-ctas-scrollable-row {
    padding-left: 0;
    padding-top: 1rem;
}

#view-digital-coupons-container {
    margin: 4px 24px;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
        0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

#home-screen-info-container {
    margin-top: 4px;
    margin-bottom: 4px;
}

/* #give-to-favorites-ctas-scrollable-row .react-swipeable-view-container {
  width: 50%;
} */

#match-not-found-contact-member-services-button {
    margin-left: 0 !important;
}

.pullRefreshContainer .ptr__children {
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.hiddenScrollForPullToRefresh .ptr__children {
    overflow: hidden !important;
}

#giving-amount-manual-amount-input::placeholder {
    text-align: center;
}

.localImpactContainer .react-swipeable-view-container>div[aria-hidden='false'] {
    height: 100%;
    padding-bottom: 1rem;
}

.localImpactContainer .react-swipeable-view-container {
    height: 100% !important;
}

#localImpactContainer .react-swipeable-view-container {
    height: 100% !important;
}

.localImpactContainer .react-swipeable-view-container>div[aria-hidden='true'] {
    height: 800px;
    overflow: hidden !important;
}

#storeInfoContainerId::-webkit-scrollbar {
    scrollbar-width: none;
}

#store-info-scrollable-row {
    padding: 0px;
    display: flex;
    gap: 24px;
    align-items: center;
}

#gift-card-container {
    margin: 20px 1.5rem 0px;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
        0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
    border-radius: 15px 15px 0px 0px;
    background: #cc0000;
}

#gift-balcnce-card-container {
    background: #ffffff;
    border-radius: 0px 0px 15px 15px;
    height: 44px;
    margin: 0px 1.5rem 0;
    cursor: pointer;
}

#scan-a-product-card-container {
    background: #ffff;
    border-radius: 15px;
    height: 100%;
    margin: 15px 1.5rem 0;
    cursor: pointer;
}

#classroom-srw-number-card .MuiCardContent-root {
    height: 100vh;
    padding: 16px 0 0 !important;
}

#zipcode-modal-input-helper-text {
    color: red;
    font-weight: 550;
}

#shopping-list-scrollable-row .MuiPaper-root .MuiCardContent-root {
    align-items: center;
    cursor: pointer;
    height: 50px;
    padding-top: 23px;
    gap: 12px;
    padding: 10px 8px !important;
    justify-content: space-evenly;
    display: flex;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#shopping-list-scrollable-row {
    justify-content: space-between;
    background: transparent;
    display: flex;
    margin: 1rem 1.5rem 2rem;
    gap: 11px;
}

#shopping-list-scrollable-row .MuiPaper-root {
    width: 166px;
    border-radius: 15px;
}

@media only screen and (min-width: 400px) {
    #shopping-list-scrollable-row .MuiPaper-root {
        border-radius: 15px;
    }
}

@media only screen and (max-width: 390px) {
    #shopping-list-scrollable-row .MuiPaper-root {
        border-radius: 15px;
    }
}

.available-shipping-products-hourdesc,
#available-shipping-products-hour,
#supply-list-cta-scrollable-row,
#featured-list-cta-scrollable-row,
#classroom-rewards-cta-scrollable-row,
#shipping-list-scrollable-row {
    padding-left: 1.5rem;
}

#recently-viewed-list-scrollable-row {
    padding-left: 0rem;
}

#product-search-input-container #product-search-input::placeholder {
    color: #999999;
    font-weight: 400;
    font-size: 14px;
    font-family: 'StaplesNorms', 'Roboto', 'Helvetica Neue', sans-serif;
}

.overflowHidden {
    overflow: hidden;
}

iframe {
    border: none;
}

iframe #sparqHeaderContainer,
iframe #mainHeader,
iframe #footerContainer,
iframe #Index_footer__P40VL {
    display: none;
}

/* Captcha styles */
#media-container {
    text-align: center;
}

#nucaptcha-player-ns {
    display: none;
}

#new-challenge-link {
    display: flex;
}

#new-challenge-link a {
    text-decoration: none;
    font-family: 'StaplesNorms', 'Roboto', 'Helvetica Neue', sans-serif;
    color: #086DD2;
    font-size: 14px;
}

#player-mode {
    margin-left: auto;
}

#nucaptcha-answer {
    width: 100%;
    height: 35px;
    border: 0px none;
    border-bottom: 2px solid #101820;
    line-height: 20px;
}

#nucaptcha-answer:focus-visible,
#nucaptcha-answer:focus {
    outline: 0px;
}

#confirmation-screen-for-rewards .MuiCardContent-root {
    height: 100vh;
    padding: 0;
}

.MuiTabs-flexContainer {
    justify-content: center;
}

.MuiTabs-indicator {
    border: 2px solid #c00;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-radius: 2px;
}

.MuiTab-textColorInherit {
    opacity: 0.5;
}

.MuiTab-textColorInherit.Mui-selected {
    font-weight: 700 !important;
    font-family: 'StaplesNormsMedium' !important;
}

#available-shipping-products-hour {
    padding-left: 1rem;
}

/* Remove default password show/hide icon in MUI */
input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
    display: none;
}

/* make backdrop of drawer little more black */
.MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.87) !important;
}

#point-history {
    border: 0;
}

.mainContainerNotAllowApp {
    height: 100vh;
    width: 100vw;
    background-color: #ffffff;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.gotItBtnAlign {
    width: auto;
    color: #086dd2 !important;
    font-weight: bold;
    text-decoration: underline !important;
    font-size: 16px;
}

.productDialog {
    z-index: 100 !important;
}

.apptentive-note-content {
    z-index: 510 !important;
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fade-in {
    animation: fadeIn 1s ease forwards;
}

.fade-out {
    animation: fadeOut 1s ease forwards;
}

.dropdown-icon {
    transition: transform 0.3s ease;
}

.dropdown-icon.rotate {
    transform: rotate(180deg);
}

/* ////////////////////// barcode css\\\\\\\\\\\\\\\\\\\\\\\\ */
.inner-box {
    width:100%;
    height: 100%;
    border-radius: 10px;
    /* position: relative; */
    /* border: '5px solid black', */
}
.corner {
    position: absolute;
    top: 0;
    bottom: 5px;
    left: 0;
    right: 0;
}
.corner span {
    position: absolute;
    width: 40px;
    height: 40px;
    border: 5px solid transparent;
    /* border-radius: 8px; */
}
.corner .topLeft {
    top:0;
    left:0;
    border-top: 5px solid white;
    border-left: 5px solid white;
    border-radius: '10px';
    border-top-left-radius: 16px;
}
.corner .topRight {
    top:0;
    right:0;
    border-top: 5px solid white;
    border-right: 5px solid white;
    border-radius: '10px';
    border-top-right-radius: 16px;
}
.corner .bottomLeft {
    bottom:0;
    left:0;
    border-bottom: 5px solid white;
    border-left: 5px solid white;
    border-radius: '10px';
    border-bottom-left-radius: 16px;
}
.corner .bottomRight {
    bottom:0;
    right:0;
    border-bottom: 5px solid white;
    border-right: 5px solid white;
    border-radius: '10px';
    border-bottom-right-radius: 16px;
}
/* ////////////////////// barcode css\\\\\\\\\\\\\\\\\\\\\\\\ */


.withTileContainerHeight {
    height: calc(100dvh - 66px);
    height: calc(100vh - 66px);
}
.withoutTileContainerHeight {
    height: 100dvh;
    height: 100vh;
}

@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';
@use 'stylesheets/layout_classes.module.scss';

.labelAlign {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin-bottom: 10px;
    display: flex;
    color: $darkgray;
}

.btnContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.fieldContainer {
    margin-bottom: 20px;
}

.inputFieldAlign {
    font-family: StaplesNormsRegular;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 10px;
    height: 50px;
    outline: none;
}

.inputFieldActive {
    font-family: StaplesNormsRegular;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 10px;
    background: #F2F2F2;
    height: 50px;
    outline: none;
    box-shadow: 0px 0px 0px 4px #CC00001A;
}

.container {
    background: #ffffff;
    height: 100vh;
    padding: 30px;
}

.backIcon {
    margin-bottom: 40px;
}

.linkReSendalign {
    color: $lightblue;
    display: flex;
}

.errorAlign {
    color: $red;
    margin-top: 10px !important;
}
.emailAutoComplete {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    z-index: 99;
    list-style-type: 'none';
    padding: 10px 0 0;
    margin: -8px 0 0;
    border: 1px solid #00000033;
    border-radius: 0 0px 10px 10px;
}
.emailItem {
    list-style: none;
    padding: 4px 10px;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: $black;
}

.emailItem:hover {
    background-color: lightgrey;
}

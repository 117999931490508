@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';
@use 'stylesheets/layout_classes.module.scss';
@use 'stylesheets/border_classes.module.scss';

// OFFER CARD STYLES

@media screen and (min-width: 390px) {
    .horizontalCardContainer {
        min-width: 14rem;
    }
}

@media screen and not (min-width: 390px) {
    .horizontalCardContainer {
        min-width: 12rem;
    }
}

.horizontalCardContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: $white;
    border-radius: 10px;
    gap: 1rem;
    // min-width: 12rem;
}

.verticalCardContainer {
    @extend .horizontalCardContainer;
    justify-content: space-between;
}

.baseCardSection {
    column-gap: 2rem;
    row-gap: 1rem;
    display: flex;
    align-items: center;
}

.horizontalCardDetailsSection {
    @extend .baseCardSection;
}

.horizontalCardActionSection {
    @extend .baseCardSection;
}

.verticalCardDetailsSection {
    @extend .baseCardSection;
    flex-direction: column;
    height: 215px;
}

.verticalCardActionSection {
    @extend .baseCardSection;
    height: 110px;
    display: flex;
    flex-direction: column-reverse;
    column-gap: 0.5rem;
}

.horizontalCardImg {
    max-width: 30%;
}

.verticalCardImg {
    max-height: 10vh;
    max-width: 100%;
}

.horizontalCardTextSection {
    padding-top: 1.5rem;
    align-self: flex-start;
}

// OFFER LIST STYLES

.horizontalListContainer {
    display: flex;
    gap: 1rem;
    padding: 0 1rem;
    overflow-x: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.verticalListContainer {
    @extend .horizontalListContainer;
    flex-direction: column;
    padding: 0;
    overflow: hidden;
}

.infoBar {
    position: absolute;
    padding: 1rem;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.pillList {
    display: flex;
    gap: 0.5rem;
    list-style: none;
    padding: 0;
}

.pill {
    border: none;
    background-color: $darkBlue;
    font-size: 10px;
    color: $white;
}

.offerConditions {
    max-width: 30%;
}

.offerDesciption {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.cardButton {
    max-width: 150px;
    height: 50px;
}

.progressBarContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.progressBar[value] {
    background-color: $extraLightgray;
    border: none;
    border-radius: 0.3125rem;
    height: 0.5rem;
}

.progressBar[value]::-webkit-progress-bar {
    background-color: $extraLightgray;
    border-radius: 0.3125rem;
}

.progressBar[value]::-webkit-progress-value {
    background-color: $purple;
    border-radius: 0.3125rem;
}

.progressBar[value]::-moz-progress-bar {
    background-color: $purple;
    border-radius: 0.3125rem;
}

.progressBar[value]::-ms-fill {
    background-color: $purple;
    border-radius: 0.3125rem;
}

// INFO DRAWER STYLES
.infoWrapper {
    padding: 1rem 2rem;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.infoOfferDetailsSection {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.infoOfferImage {
    align-self: center;
    max-height: 120px;
}

.ignoreUserSelect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.pillOffer {
    background-color: #f1f1f2;
    margin-bottom: 7px;
    margin-top: 3px;
}
.showExpDate {
    display: inline-block;
    margin-right: 5px;
}
.shopNowContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.fshopNowContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}
.shopNowContainerWrapper {
    border-top: 1px solid rgb(238, 238, 238);
    padding-top: 0.8rem;
}
.shopBtn {
    margin-left: auto;
    height: 36px;
    background-color: #cc0000;
}
.shopBtnv {
    width: 100%;
    height: 30px;
    background-color: #cc0000;
}
.shopBtnInfo {
    width: 100%;
    height: 46px;
    background-color: #cc0000;
}
.pillPlaceHolder {
    min-height: 25px;
}

.offerCon {
    margin-top: -0.9rem !important;
}

// Activate button
.activateButton {
    border: none;
    color: $white;
    font-family: $normal;
    background-color: $purple;
    border-radius: $fullRounded;
    padding: 8px 26px;
    position: relative;
    white-space: nowrap;
}

.activatedButtonContent {
    display: flex;
    align-items: center;
    justify-content: center;
}
.expDateText {
    white-space: nowrap;
}
.showExp {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: baseline;
}
.showExpVer {
    flex-direction: column !important;
    align-items: center !important;
}

@media only screen and (min-width: 360px) and (max-width: 429px) {
    .activateButton {
        padding: 8px 15px !important;
    }
    .showExp {
        gap: 0px !important;
    }
    .horizontalCardActionSection {
        column-gap: 1rem !important;
    }
}

@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';
@use 'stylesheets/layout_classes.module.scss';

.screen {
    flex-grow: 1;
    background: $white;
    display: flex;
    flex-direction: column;
    height: 100vh !important;
    justify-content: space-between;
    padding: 30px;

    .headerSubTitle {
        color: $smokeywhite;
        text-align: left;
    }
}

.headerLogo {
    margin-top: 2.4rem;
    align-self: self-start;
}

.headerLogoWrapper {
    align-self: flex-start;
    padding: 4rem 0 0 2rem;
}

.headerLogoWrapper {
    .welcomeHeaderSubTitle {
        color: $smokeywhite;
        font-weight: $bold;
        line-height: 22px;
        letter-spacing: -0.23px;
        text-align: left;
        font-size: 1rem;
        align-self: flex-start;
    }
}

.container {
    min-height: 10.75rem;
    border-radius: 15px;
    padding: 31px 27px;
    margin: -27rem 1.5rem 0px;

    .title {
        font-size: 1.1rem;
        color: $black;
        font-weight: $bold;
    }
}

.verifyContainer {
    border-radius: 15px;
    padding: 24px;
    margin: -5.5rem 1.5rem 0px;

    .title {
        font-size: 1.1rem;
        color: $black;
        font-weight: $bold;
    }
}

.lookupForm {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.textFieldLabel {
    label: {
        text-align: center;
        top: unset;
        bottom: 2rem;
    }

    label.Mui-focused {
        width: 150%;
        text-align: left;
    }
}

.textFieldLabelError {
    label: {
        bottom: 3.4rem;
    }
}

Button.createAccountButton {
    font-family: StaplesNormsRegular;
    height: 50px;
    border-radius: 100px;
    background: $red;
    color: $white;
    text-transform: none;
    font-size: 1rem;
    font-weight: 500;
    box-shadow: none;
    margin-top: 23px;
}

Button.createAccountButton:hover {
    background-color: $red;
}

Button.createAccountButton:active {
    background-color: $red;
}

Button.loginButton {
    font-family: StaplesNormsRegular;
    height: 50px;
    border-radius: 100px;
    background: $red;
    color: $white;
    text-transform: none;
    font-size: 1rem;
    font-weight: 500;
    box-shadow: none;
}

// Button.loginButton:hover {
//     background-color: #cc0000;
// }

// Button.loginButton:active {
//     background-color: #cc0000;
// }

.bottomContainer {
    margin-bottom: 8.5rem;

    .footerText {
        // font-size: 12px;
        // color: #585858;
        // .footerLinkText {
        font-size: 14px;
        color: $lightblue;
        margin-bottom: 1rem;
        // }
    }
}

/////////////////////////////////////////////////////////////////
///
.accountContainer {
    min-height: 10.75rem;
    border-radius: 15px;

    .title {
        font-size: 1.1rem;
        color: $black;
        font-weight: $bold;
    }
}

.checkBoxAlign {
    padding: 0 8px 0 0;
    width: 24px;

    .MuiCheckbox-colorSecondary.Mui-checked {
        color: #000000 !important;
    }
}

.subLinkSm {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    // color: $lightblue;
    cursor: pointer;
    text-decoration: underline;
}

.subLinkSm span {
    // color: $darkCyanBlue;
    font-weight: normal;
}

.createUserContainer {
    margin: 0;
    border-radius: 0;
    height: 100vh;
    padding: 24px;
    overflow: auto !important;
    position: relative;

    .alignBackBtn {
        margin-bottom: 20px;
    }

    .MuiFormLabel-root {
        color: #444444;
    }

    .title {
        font-size: 26px;
        font-weight: bolder;
        line-height: 32px;
        letter-spacing: 0.13px;
        color: $darkCyanBlue;
        margin-bottom: 20px;
        -webkit-text-stroke: 0.8px black;
    }

    .closeBtnAlign {
        position: absolute;
        left: 20px;
        top: 14px;
        background: none;
        border: none;
    }

    .textContainer {
        display: flex;
        flex-direction: column;
    }

    .textContainer .MuiFormHelperText-root {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0px;
        text-align: left;
        color: #444444;
    }

    .textContainer .MuiFormHelperText-root.Mui-error {
        color: #ff3b30;
    }

    .checkboxContainer {
        display: flex;
        align-items: flex-start;
        column-gap: 0.5rem;
    }

    .checkboxContainer .subLink {
        @extend .subLinkSm;
    }

    .checkboxContainer .subLink div {
        color: $darkgray;
        text-decoration: underline;
    }

    .checkboxContainer .MuiRadio-colorSecondary {
        color: $darkgray;
        width: 18px;
        height: 18px;
        border-radius: 4px 0px 0px 0px;
        border: 1px 0px 0px 0px;
        opacity: 0.2px;
    }

    .stapleRadioButonGroup {
        display: flex;
        gap: 9px;
        flex-direction: column;
    }

    .stapleRadioButonGroup .MuiFormControlLabel-root {
        height: 16px;
        margin-left: 0;
        margin-right: 0;
    }

    .stapleRadioButonGroup .MuiFormControlLabel-root .MuiIconButton-label {
        width: 14px;
        margin-right: 10px;
    }

    .loginButton {
        height: 2.5rem;
        border-radius: 1.885rem;
        background: $darkGreen;
        color: $smokeywhite;
        text-transform: none;
        font-size: 1rem;
        font-weight: 500;
    }

    .loginButton:hover,
    .loginButton:active {
        background-color: $darkGreen;
    }

    .redioGroupAlign {
        display: flex;
        gap: 9px;
        flex-direction: column;
        margin-bottom: 24px;
    }

    .redioGroupAlign .MuiButtonBase-root {
        padding: 0;
    }

    .redioTwoAlign {
        display: flex;
        gap: 9px;
        flex-direction: column;
    }

    .textImageContainer {
        display: flex;
        position: relative;
        margin: 0;
    }

    .eyeImageAlign {
        position: absolute;
        right: 10px;
        padding: 0;
        top: 45px;
        min-width: 0;
        background: none;
        border: none;
    }

    .checkedImageAlign {
        position: absolute;
        right: 30px;
        padding: 0;
        top: 13px;
        min-width: 0;
    }

    .moreInfo {
        width: 1.8rem;
    }

    .radio.checked {
        color: $darkCyanBlue;
    }

    .chevron {
        position: absolute;
        right: 0;
        top: 15px;
    }

    .root .MuiFormLabel-root {
        color: #ff3b30;
    }
}

.disableEmailTextField::before {
    pointer-events: all !important;
}

.selectWrapper {
    position: relative;
}

.industryContainer {
    padding-bottom: 24px !important;

    .MuiInput-underline:before {
        border-bottom-color: rgba(0, 0, 0, 0.45);
        border-width: 1px;
    }

    .MuiInput-underline:after {
        border-bottom-color: #ff3b30;
    }

    .MuiSvgIcon-root {
        display: none;
    }

    .industrySelectLabel {
        padding-left: 5px;

        .Mui-focused {
            color: rgb(28, 27, 27);
        }
    }
}

// ***********************************
.textFieldLabel label {
    text-align: center;
    bottom: 2rem;
}

.textFieldLabel .Mui-focused label {
    width: 150%;
    text-align: left;
}

.textFieldLabelError label {
    bottom: 3.4rem;
}

.form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;

    .forgotPassword {
        font-size: 14px;
        font-weight: 500;
        line-height: 18.18px;
        color: $lightblue;
        cursor: pointer;
        margin-top: 16px;
    }
}

.textImageContainer {
    display: flex;
    position: relative;
    margin-top: 1rem;

    .eyeImageAlign {
        position: absolute;
        right: 0;
        padding: 0;
        top: 13px;
        min-width: 0;
    }
}

///////////////////////////////////////////////////////
.easyLogo {
    margin-bottom: 1.2rem;
    margin-top: 0.5rem;
}

.welcomeText {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6rem;

    .linkText {
        color: $lightblue;
    }
}

.skipText {
    @extend .subLinkSm;
    text-align: center;
    margin-top: 1rem !important;
}

.list {
    width: 250px;
    padding: 1.5rem 2rem 2rem 1.5rem;

    .fullList {
        width: auto;
    }

    .title {
        font-size: 1.2rem;
        font-weight: $bold;
        margin: 1.5rem 0 1rem 0;
    }

    .runningText {
        font-size: 0.9rem;
        padding-bottom: 1rem;
    }

    .slider {
        width: 80px;
        background: $veryLightGray;
        color: $veryLightGray;
        border-radius: 50px;
        height: 7px;
        margin: 0 auto;
    }
}

.tosTabs {
    justify-content: center;
}

.emailAutoComplete {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    z-index: 99;
    list-style-type: 'none';
    padding: 10px 0 0;
    margin: -8px 0 0;
    border: 1px solid #00000033;
    border-radius: 0 0px 10px 10px;
}

.emailItem {
    list-style: none;
    padding: 4px 10px;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: $black;
}

.emailItem:hover {
    background-color: lightgrey;
}

.maskVal {
    height: 2rem;
    padding-top: 1.2rem;
}

.maskValWidth {
    width: 100%;
}

.dragger {
    height: 8px;
    background: $veryLightGray;
    position: absolute;
    top: 0;
    left: 50%;
    width: 20%;
    transform: translateX(-50%);
    border-radius: 2rem;
}

.guest {
    margin-top: 0px !important;
}

.dialogToolbar {
    display: flex;
    background: $red;
    align-items: center;
    padding: 8px 16px;

    button {
        background: transparent;
        padding: 0;

        span {
            display: flex;
            align-items: center;
        }

        img {
            height: 20px;
            margin-right: 5px;
        }
    }
}

.accessibleBtn {
    background: transparent;
    border: 0 none;
}

Button.checkoutGuestButton {
    font-family: StaplesNormsRegular;
    height: 50px;
    border-radius: 100px;
    background: $black;
    color: $white;
    text-transform: none;
    font-size: 1rem;
    font-weight: 500;
    box-shadow: none;
    margin-top: 20px;
}

Button.checkoutGuestButton:hover {
    background-color: $black;
    color: $white;
}

Button.checkoutGuestButton:active {
    background-color: $black;
    color: $white;
}

.firstTimeUserContainer {
    display: flex;
    background-image: url('../../assets/bgs/firstTimeUserBg.jfif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-direction: column;
    height: 100dvh;
    height: 100vh;
    width: 100vw;
}

.firstTimeUserCarouselTagLines {
    margin: 0;
    padding: 1rem;
    color: #ffffff;
}

.firstTimeUserCarouselContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 40px;
}

.firstTimeUserBottomContentContainer {
    display: flex;
    background-color: #ffffff;
    border-radius: 24px 24px 0 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 20px;
    padding: 40px 50px;
}

.btnAlign {
    width: 270px;
    height: 46px;
}

.inputFieldAlign {
    font-family: StaplesNormsRegular;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 10px !important;
    background: #ffffff !important;
    height: 50px;
    outline: none;
    box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
    box-shadow: inset 0 0 0 2rem #ffffff !important;
}

.inputFieldActive {
    font-family: StaplesNormsRegular;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 10px !important;
    background: #f2f2f2 !important;
    height: 50px;
    outline: none;
    box-shadow: none !important;
    font-weight: 400;
}

.alignPasswordShowHide {
    position: absolute;
    top: 45px;
    right: 15px;
}

.dividerLine {
    border-top: 1px solid #000000;
    width: 100%;
    height: 0px;
    opacity: 0.1;
    margin: 0px auto;
}

.logoAlign {
    width: 120px;
    height: 22.29px;
}

.loginContainer {
    margin-top: 30px;

    .title {
        line-height: 32px;
    }
}

.labelAlign {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin-bottom: 10px;
    display: flex;
    color: $darkgray;
}

.btnContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.fieldContainer {
    margin-bottom: 20px;
}

.errorAlign {
    color: $red;
    display: inline-block;
}

.linkAlign {
    color: $lightblue;
    display: inline-block;
    margin-left: 5px;
}

.validationPass {
    color: #75c016;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    font-family: 'StaplesNormsMedium';
}

.validationError {
    color: #343434;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
}

.alignPasswordValidation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;

    img {
        width: 11px;
        height: 11px;
    }
}

.passwordResetAlign {
    margin-bottom: 20px;
}

.createTitle {
    font-size: 26px;
    line-height: 32px;
    color: $black;
    margin: 0 0 20px;
    font-weight: 400;
    font-family: 'StaplesNormsBold';
}

.customCheckbox {
    color: #000000 !important;
    border-radius: 4px;
    opacity: 0.2;
}

.customCheckboxChecked {
    color: #cc0000 !important;
}

.errorMsg {
    color: $red;
    margin-bottom: 30px;
}

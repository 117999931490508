@use 'stylesheets/variables' as *;


.storeModeContainer {
    background-size: 200px;
    background-image: url('../../assets/bgs/bgRedUserProfile.svg');
    height: 28vh;
    background-color: $red;
    user-select: none;
    @media screen and (max-width: 360px) {
        height: 35vh;
    }

    .card {
        margin: 0 1rem 1rem 1rem;
        box-shadow: none;
        padding: 10px 1.5rem 15px 1.5rem;

        .actionLink {
            padding: 5px 0;
            display: block;
            margin: 5px !important
        }
    }

    .smallCardContainer {
        gap: 1.5rem;
        margin: 0 1rem 1rem 1rem;

    }

    .noEnrollCard {
        margin: 0 1rem 1rem 1rem;
        box-shadow: none;
        padding: 30px 20px;
    }
    .enrollNowButton {
        background-color: $purple;

    }

    .linkText {
        color: #086DD2;
        cursor: pointer;
    }

    .smallCard {
        margin: 0;
        padding: 0;
        padding: 10px;
        flex: 0 50%;
    }

    .offersText {
        display: flex;
    }

    .signInButton {
        font-weight: bold;
        padding-left: 0.3rem;
    }

    .exitStoreModeText {
        color: gray;
        line-height: 18px;
        text-decoration: underLine;
        margin: 1.5rem auto !important;
    }
}

.headerContainer {
    margin-top: 2rem;
    padding: 1.4rem 1.8rem;
    @media screen and (max-width: 395px) {
        margin-top: 1rem;
        padding-bottom: 1.4rem;
    }
    @media screen and (max-width: 395px) {
        margin-top: 0.6rem;
        padding-bottom: 1.1rem;
    }
    @media screen and (max-width: 375px) {
        margin-top: 0.5rem;
        padding-bottom: 1rem;
    }
    @media screen and (max-width: 360px) {
        margin-top: 2.5rem;
        padding-bottom: 1.5rem;
    }
    @media screen and (max-width: 355px) {
        margin-top: 3rem;
    }
}
.quickLickContainer{
    display: flex;
    justify-content: space-between;
    padding: 0rem 2rem 1.45rem;
    @media screen and (max-width: 375px) {
        padding-bottom: 1.2rem;
    }
    @media screen and (max-width: 355px) {
        padding: 0rem 1.8rem 1.45rem;
    }
}
.guestModeQuickLinkCard {
    padding: 2rem;
    box-shadow: none !important;
    @media screen and (max-width: 415px) {
        padding: 1.65rem;
    }
    @media screen and (max-width: 395px) {
        padding: 1.4rem;
    }
    @media screen and (max-width: 375px) {
        padding: 1.2rem;
    }
    @media screen and (max-width: 360px) {
        padding: 0.95rem;
    }
}

.actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    // @media screen and (max-width: 375px) {
    //     margin-top: -5px;
    // }
}

.closeButton {
    background: none;
    border: none;
    position: absolute;
    top: 2rem;
    right: 0.9rem;
    @media screen and (max-width: 375px) {
        top: 1.3rem;
    }
    @media screen and (max-width: 360px) {
        top: 1.6rem;
    }
    @media screen and (max-width: 355px) {
        top: 2rem;
    }
}

.header {
    display: flex;
    color: $white;
    align-items: center;

    h2 {
        margin-left: 15px;
        margin-right: 10px;
    }
}
.logo{
    width: 50px !important;
    height: 50px !important;
    @media screen and (max-width: 375px) {
        width: 40px !important;
        height: 40px !important;
    }
}

.heading {
    font-weight: bold;
    font-size: 1.6rem !important;
    @media screen and (max-width: 375px) {
        font-size: 1.1rem !important;
    }
}

.yourStore {
    color: $white;
    font-family: 'StaplesNormsRegular';
    font-size: 1.1rem !important;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    @media screen and (max-width: 375px) {
        font-size: 1rem !important;
        margin-top: 0.45rem !important;
        margin-bottom: 0.45rem !important;
    }
    @media screen and (max-width: 355px) {
        margin: 1rem 0rem !important;
    }
    @media screen and (max-width: 355px) {
        margin: 0.8rem 0rem !important;
    }
}

.renderedCardNumber {
    height: 50px;
    width: 100%;
}

.coin {
    vertical-align: top;
    margin-right: 10px;
    background-image: url('../../assets/icons/iconCoin.png');
    display: inline-block;
    width: 24px;
    height: 24px;
}

.calculator {
    padding: 5px;
    margin-left: 10px;
}

.featuredCard {
    display: flex;
    align-items: center;
    gap: 2rem;

    img {
        max-width: 25%;
    }
}

.offerHeader {
    height: 64px;
    background-color: $red;
    padding: 20px 16px;
    display: flex;
    justify-content: space-between;
    position: sticky;
    z-index: 99;
    top: 0;

    .arrowBtn {
        color: white;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;

        >span {
            display: flex;
            align-items: center;
            text-decoration: none;
        }

        img {
            margin-right: 20px;
        }
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    margin: 2rem 2rem 0 2rem;

    .pointsWraper {
        width: 100%;
    }

    .dollerWraper {
        width: 100%;
    }

    .msgText {
        font-weight: 500;
    }

}

.hint {
    margin: 0.5rem 2rem 1rem 2rem;

    .hintText {
        color: #585858;
    }
}

.linkText {
    color: #086dd2;
    justify-content: start;
    margin-left: 2rem;
    margin-top: 2rem;
}

.advWrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.dlrText {
    color: #4CCC65;
    font-size: 13px;
    font-weight: 500;
}

.hintWrap {
    margin-right: 10px;
}

.ponWrap {
    margin-left: 10px;
}

.inputBox {
    font-weight: bold !important;
    font-size: 2rem !important;
    font-family: 'StaplesNormsBold' !important;

    input {
        padding-top: 12px !important;
        padding-bottom: 12px !important;
    }
}

.seletWrap {
    width: 100%;
    position: relative;
}

.selectBox {
    font-weight: bold !important;
    font-size: 2rem !important;
    font-family: 'StaplesNormsBold' !important;

    div {
        padding: 12px !important;
    }
}

.dolInput {
    width: 55%;
    input {
        text-align: right;
    }

    div {
        width: 100%;
        font-weight: bold !important;
        font-size: 2rem !important;
        font-family: 'StaplesNormsBold' !important;
        height: 3.05rem !important;
        padding-left: 3px;
        padding-top: 5px;
        ::before {
            border-bottom: none !important;
        }
    }
}

.dolSelect {
    width:45%;
    div {
        width: 100%;
        
    }
    .dolSelectBox {
        font-weight: bold !important;
        font-size: 2rem !important;
        font-family: 'StaplesNormsBold' !important;
        
    
        div {
            padding: 12px 12px 12px 8px !important;
            
        }
    }
}
.dolSelectBox::before {
    border-bottom: none !important;
}

.comboInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    border: 1px solid lightgray;
    border-radius: 5px;
}

.cardInfo {
    width: 67%;
}
.ellpsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.activeButton {
    color: $purple;
    font-weight: bold;
    svg {
        margin-top: -3px;
    }
}
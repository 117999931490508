@use 'stylesheets/variables' as *;
@use 'stylesheets/color_classes.module.scss';
@use 'stylesheets/border_classes.module.scss';

.container {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0;
    background-color: $white;
    @extend .rounded;
    @extend .borderBase;
    @extend .borderExtraLightgray;
    border-width: 2px;

    .checkmarkContainer {
        position: absolute;
        visibility: hidden;
        top: 10px;
        right: 10px;
    }
    button {
        background-color: $white;
        border: 0 none;
        height: 100%;
        width: 100%;
        @extend .rounded;
        @extend .borderBase;
    }
}

.containerSelected {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0;
    background-color: $white;
    @extend .rounded;
    @extend .borderBase;
    @extend .borderPurple;
    border-width: 2px;

    .checkmarkContainer {
        position: absolute;
        visibility: visible;
        top: 10px;
        right: 10px;
    }
    button {
        background-color: $white;
        border: 0 none;
        height: 100%;
        width: 100%;
        @extend .rounded;
        @extend .borderBase;
    }
}
.cardInput {
    padding-left: 25px;
    padding-bottom: 20px;
}

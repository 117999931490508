.visuallyHidden {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.ariaButton {
    background: none;
    border: 0 none;
}
.fixedTitle{
    position: fixed;
    z-index: 2;
    top: 8px;
    animation: scale-down 1ms ease both;
}

@keyframes scale-down {
    0% {
        transform:  scale(1);     
    }
    100% {
        transform:  scale(.75); 
    }
}
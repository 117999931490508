.serviceContainer {
    display: flex;
    align-items: center;
    padding: 10px !important;
    flex-direction: column;
    border-radius: 15px;
    width: 84px;
    height: 90px;
    justify-content: "space-between";
}

.servicetextInfo {
    font-size: 12px !important;
    color: #585858;
    line-height: 12px !important;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.imageContainer {
    display: flex;
    align-items: center;
    height: 60%;
}
.serviceImageInfo {
    width: 30px;
}

.standOutCard {
    display: flex;
    gap: 15px;
    margin-bottom: 1rem;
     .serviceContainer {
        background-color: #fff;
        width: unset;
        height: unset;
        flex-basis: 50%;
        align-items: flex-start;
        padding: 1rem !important;
       
    }
    .imageContainer{
        width: 56px;
        height: 56px;
        .serviceImageInfo {
            width: 100%;
        }
    }
    .servicetextInfo {
        font-weight: 600;
        font-size: 1rem !important;
        font-family: 'StaplesNormsBold';
        line-height: 24px !important;
        text-align: left;
        color: #000;
        margin-top: 15px;
    }
}
@use 'stylesheets/layout_classes.module.scss';
@use 'stylesheets/variables' as *;

.hr {
    width: 100%;
    border-radius: 200px;
    margin-left: 0;
}
.paperDrawer {
    border-radius: 0px !important;
}
.indicator {
    background: rgb(112, 57, 132);
    height: 7px;
    width: 20%;
    margin: 10px auto 15px;
    border-radius: 2rem;
    margin-top: 10px;
    top: 10px;
    left: 43%;
}

.drawer {
    max-height: 100vh;
    overflow: auto;
    //  padding: 0rem 2rem 2rem 2rem;
}

.title {
    font-size: 16px;
    font-weight: 450;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #000000 !important;
    padding: 40px 20px 25px !important;
    text-decoration: underline !important;
    justify-content: flex-start !important;
}

.iconBack {
    display: flex;
    justify-content: flex-start;
    z-index: 1;
    padding: 40px 20px 0px !important;
}

.spinnerContainer {
    width: 100vw;
    height: 91vh;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pointHistoryHeaderAlign {
    color: #000000;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
}

.headerContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 20px;
}

.erWrapper {
    background: $purple;
    padding-bottom: 20px;
    margin-top: -1px;
}

.pointsInfoContainer {
    padding: 0 1rem;
}
.purpleBg {
    background: $purple;
}
.easyRewardsWrapper {
    background-color: #ffffff;
    border-radius: 1rem;
    padding: 10px 2px;
    margin-top: 16px;
}
.renderedCardNumber {
    height: 90px;
    width: 100%;
}

.textRed {
    color: $red;
    text-align: center;
    margin: 16px 0;
}

.pointsButton {
    margin-top: 1rem;
}

.infoSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
    margin: 20px 0;
}
.staplesGray {
    color: $darkgray;
}
.infoTitle {
    margin-top: 16px;
}
.termsAndConditionsWrapper {
    padding: 0 1rem;
}
.termsAndConditions {
    background: $veryLightGray;
    padding: 1rem;
    border-radius: 1rem;
    margin: 1rem 0;
    p:not(:first-child) {
        margin-top: 2rem !important;
    }
}
.calculatorWrapper {
    height: 100vh;
    background: #fff;
}

.favoriteSelectionCarouselContainer {
    display: flex;
    flex-direction: column;
    height: 95vh;
}
